import { useEffect, useState } from 'react';

const useScroll = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const detectScrolled = () => {
    if (window.scrollY > 10 && window.innerWidth > 640) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', detectScrolled, { passive: true });

    return () => {
      window.removeEventListener('scroll', detectScrolled);
    };
  }, []);
  return isScrolled;
};

export default useScroll;
