import React from 'react';

const SectionWrapper = ({ id, className, children }) => {
  return (
    <section id={id} className={`${className} bg-primary`}>
      <article className='h-full flex flex-col items-center text-gray-300 font-medium sm:pt-16'>
        {children}
      </article>
    </section>
  );
};

export default SectionWrapper;
