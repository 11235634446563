import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SectionWrapper from './SectionWrapper';
import GlitchTitle from './GlitchTitle';

const About = () => {
  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: {eq: "profile_pic.jpeg"}) {
        id
        name
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
    }
  `);
  const profilePic = getImage(image);
  return (
    <SectionWrapper id='about'>
      <GlitchTitle content='About Me'/>
      <div className='content-wrapper w-2/3 md:flex'>
        <GatsbyImage
          image={profilePic}
          alt='portrait of Gilles Rusca'
          className='min-h-52 md:w-1/3 rounded-xl'
        />
        <div className='description flex flex-col justify-between md:w-2/3 md:px-4'>
          <p className='pt-2 text-justify md:pt-0'>
            I&apos;m a Swiss expat living in Central America since 2016. I grew up bilingual (french&#47;german) in a small town called Fribourg.
          </p>
          <p className='pt-2 text-justify'>
            After graduating in&nbsp;
            <a href='https://www.hevs.ch/en/hautes-ecoles/school-of-management/tourism/'>&quot;Bachelor of Science HES-SO in Tourism&quot;</a>
            &nbsp;in 2008, I spent over 10 years in the field managing a variety of businesses ranging from snow sport schools to youth hostels and upscale beach resorts.
          </p>
          <p className='pt-2 text-justify'>
            As much as I enjoyed the personal interaction with guests and making peoples holidays perfect, I decided by autumn 2018 to go for a career change and started studying web development in parallel to my work.
          </p>
          <p className='pt-2 text-justify'>When the COVID-19 pandemic hit in spring 2020 and we had to close the resort I was managing, I grabbed this opportunity to dedicate my time exclusively to studying to become a full-stack web developer </p>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default About;
