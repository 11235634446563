import React from 'react';
import About from '../components/About';
import ContactForm from '../components/ContactForm';
import Home from '../components/Home';
import NavBar from '../components/NavBar';
import Skills from '../components/Skills';
import SEO from '../components/SEO';

const Index = () => (
  <>
    <SEO/>
    <NavBar/>
    <main>
      <Home/>
      <About/>
      <Skills/>
      <ContactForm/>
    </main>
  </>
);

export default Index;
