import React, { useState } from 'react';
import styled from 'styled-components';
import Div100vh from 'react-div-100vh';
import SectionWrapper from './SectionWrapper';
import GlitchTitle from './GlitchTitle';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;

  textarea {
    resize: none;
  }

  input, textarea {
    width: 100%;
    margin: 0.5rem 0;
    color: #273446;
    padding: 5px;
  }

  @media(max-width: 640px) {
    border: 3px solid green;
  }
`;

const ContactForm = () => {
  const [state, setState] = useState({
    'bot-field': '',
  });
  const [messageSent, setMessageSent] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => {
        e.target.reset();
        setMessageSent(true);
        setTimeout(() => setMessageSent(false), 10e3);
      })
      .catch((error) => {
        setMessageError(true);
        console.log(error);
        setTimeout(() => setMessageError(false), 10e3);
      });
  };
  return (
    <Div100vh>
      <SectionWrapper id='contact' className='h-full'>
        <GlitchTitle content='Contact Me'/>
        <StyledForm
          name='contact-form'
          method='POST'
          data-netlify='true'
          netlify-honeypot='bot-field'
          onSubmit={handleSubmit}
        >
          <input type='hidden' name='form-name' value='contact-form'/>
          <p className='hidden'>
            <label>
              Don’t fill this out if you’re human:
              <input
                name='bot-field'
              />
            </label>
          </p>
          <input
            id='text-input'
            type='text'
            name='name'
            placeholder='Name'
            onChange={handleChange}
            required
          />
          <input
            id='email-input'
            type='email'
            name='email'
            placeholder='Email'
            onChange={handleChange}
            required
          />
          <textarea
            id='textarea'
            type='text'
            name='message'
            placeholder='Message'
            onChange={handleChange}
            rows={5}
            maxLength={350}
            required
          />
          <button
            type='submit'
            className='border-2 w-1/2 mt-2 hover:bg-secondary'
          >
            Submit
          </button>
        </StyledForm>
        {messageSent && (
          <div className='sent-message mt-4 p-3 w-1/2 text-center border-4 border-green-400 rounded-md bg-green-700'>
            <p>Thanks for your message, I&apos;ll get back to you ASAP!</p>
          </div>
        )}
        {messageError && (
          <div className='sent-message mt-4 p-3 w-1/2 text-center border-4 border-red-400 rounded-md bg-red-700'>
            <p>There has been an error, please try again!</p>
          </div>
        )}
      </SectionWrapper>
    </Div100vh>
  );
};

export default ContactForm;
