import React from 'react';
import styled, { keyframes } from 'styled-components';
import SectionWrapper from './SectionWrapper';
import GlitchTitle from './GlitchTitle';
import useElementOnScreen from '../hooks/useElementOnScreen';

const barsAnimation = (value) => keyframes`
    0% { width: 0; }
    100% { width: ${value}% }
`;

const StyledBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 1.25rem;
  padding: 0 5px;
  background: #314157;
  border-radius: 100px;

  div {
    width: 0;
    height: 50%;
    background: #D1D5DB;
    border-radius: 100px;
  }

  .animated {
    animation-name: ${(props) => barsAnimation(props.value)};
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }
`;

const Skills = () => {
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });

  return (
    <SectionWrapper id='skills'>
      <GlitchTitle content='My Skills'/>
      <div className='content-wrapper w-2/3 md:flex'>
        <div className='description md:px-4 w-full'>
          <p className='text-justify pb-2'>
            My main guid to become a
            {' '}
            <span className='font-black text-gray-50'>full stack developper</span>
            {' '}
            was
            {' '}
            <a href='https://www.theodinproject.com' className='font-black text-gray-50'>The Odin Project</a>
            , an open source self-paced bootcamp. I started with the
            {' '}
            <a href='https://www.theodinproject.com/paths/foundations?' className='font-black text-gray-50'>Foundations</a>
            {' '}
            and decided to follow the
            {' '}
            <a href='https://www.theodinproject.com/paths/full-stack-ruby-on-rails?' className='font-black text-gray-50'>Full Stack Ruby on Rails</a>
            {' '}
            path. Once I completed this curriculum I knew I had acquired the basics, but I was still far to be &apos;job ready&apos;.
          </p>
          <p className='text-justify pb-2'>
            With the help of my mentor from
            {' '}
            <a href='https://mentorcruise.com/' className='font-black text-gray-50'>mentorcruise.com</a>
            {' '}
            I did not only rapidly increase my skills but I also discovered
            {' '}
            <a href='https://jamstack.org/' className='font-black text-gray-50'>JAMstack</a>
            {' '}
            which absolutely fascinated me and had me also complete the
            {' '}
            <a href='https://www.theodinproject.com/paths/full-stack-javascript?' className='font-black text-gray-50'>Full Stack JavaScript</a>
            {' '}
            path.
          </p>
          <p className='text-justify pb-2 md:pb-0'>
            Now after countless hours of studying and practicing I can finally call myself a
            {' '}
            <span className='font-black text-gray-50'>Full Stack / JAMstack Developer!</span>
          </p>
        </div>
        <div className='skills flex flex-col justify-between w-full' isVisible={isVisible} ref={containerRef}>
          <h2 className='md:px-1'>HTML</h2>
          <StyledBar className='html' value={60}>
            <div className={`${isVisible && 'animated'}`}></div>
          </StyledBar>
          <h2 className='md:px-1'>CSS</h2>
          <StyledBar className='css' value={70}>
            <div className={`${isVisible && 'animated'}`}></div>
          </StyledBar>
          <h2 className='md:px-1'>JavaScript</h2>
          <StyledBar className='js' value={55}>
            <div className={`${isVisible && 'animated'}`}></div>
          </StyledBar>
          <h2 className='md:px-1'>React / GatsbyJS / NextJS</h2>
          <StyledBar className='gatsby' value={65}>
            <div className={`${isVisible && 'animated'}`}></div>
          </StyledBar>
          <h2 className='md:px-1'>ExpressJS</h2>
          <StyledBar className='express' value={35}>
            <div className={`${isVisible && 'animated'}`}></div>
          </StyledBar>
          <h2 className='md:px-1'>Git</h2>
          <StyledBar className='git' value={68}>
            <div className={`${isVisible && 'animated'}`}></div>
          </StyledBar>
          <h2 className='md:px-1'>Ruby / Ruby On Rails</h2>
          <StyledBar className='ruby' value={20}>
            <div className={`${isVisible && 'animated'}`}></div>
          </StyledBar>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Skills;
