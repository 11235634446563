import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = () => {
  const { site } = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `);
  return (
    <Helmet>
      <html lang='en'/>
      <title>{site.siteMetadata.description}</title>
      {/* Fav Icons */}
      <link rel='alternate icon' href='/favicon.png'/>
      {/* Meta Tags */}
      <meta name='viewport' content='width=device-width, initial-scale=1.0'/>
      <meta charSet='utf-8'/>
      <meta name='description' content={site.siteMetadata.description}/>
      {/* Open Graph */}
      <meta property='og:title' content={site.siteMetadata.title} key='ogtitle'/>
      <meta property='og:type' content='website'/>
      <meta property='og:image' content='/og-image.jpeg'/>
      <meta property='og:image:alt' content='Gilles Rusca - JAMstack developer'/>
      <meta
        property='og:site_name'
        content={site.siteMetadata.title}
        key='ogsitename'
      />
      <meta property='og:description' content={site.siteMetadata.description} key='ogdesc'/>
    </Helmet>
  );
};

export default SEO;
