import React from 'react';
import styled, { keyframes } from 'styled-components';

const genKeyframes = () => {
  const arr = [];
  for (let step = 0; step <= 100; step += 5) {
    arr.push(`${step}% {clip-path: inset(${Math.floor(Math.random() * 50)}% 0% ${Math.floor(Math.random() * 50)}% 0%);}`);
  }
  return arr.join(' ');
};

const glitchAnimation1 = keyframes`
    ${genKeyframes()}
  `;

const glitchAnimation2 = keyframes`
    ${genKeyframes()}
  `;

const StyledH1 = styled.h1`
    position: relative;
    &::before, &::after {
        padding: 2rem;
        color: #D1D5DB;
        content: '${({ content }) => content}';
        position: absolute;
        overflow: hidden;
        top: 0;
    }

    &::before {
        left: -1px;
        text-shadow: -1px 0 red;
        animation-name: ${glitchAnimation1};
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-direction: reverse-alternate;
    }

    &::after {
        left: 1px;
        text-shadow: 1px 0 blue;
        animation-name: ${glitchAnimation2};
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-direction: reverse-alternate;
    }
`;

const GlitchTitle = ({ content }) => {
  return (
    <StyledH1 content={content} className='relative p-8 text-4xl sm:text-8xl whitespace-nowrap mt-20'>
      {content}
    </StyledH1>
  );
};

export default GlitchTitle;
