import React from 'react';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'gatsby';
import useScroll from '../hooks/useScroll';

const NavBar = () => {
  const isScrolled = useScroll();
  return (
    <Disclosure as='nav' className={`fixed w-full z-10 transition duration-500 ${isScrolled && 'bg-secondary'}`}>
      {({ open }) => (
        <>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='flex items-center justify-end sm:justify-center h-16'>
              <div className='flex items-center'>
                <div className='hidden sm:block'>
                  <div className='flex space-x-4'>
                    {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                    <Link to='#home' className={'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md font-medium'}>
                      Home
                    </Link>
                    <Link to='#about' className={'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md font-medium'}>
                      About Me
                    </Link>
                    <Link to='#skills' className={'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md font-medium'}>
                      Skills
                    </Link>
                    <Link to='#contact' className={'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md font-medium'}>
                      Contact Me
                    </Link>
                  </div>
                </div>
              </div>
              <div className='hidden sm:ml-6 sm:block'>
              </div>
              <div className='-mr-2 flex sm:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XIcon className='block h-6 w-6' aria-hidden='true'/>
                  ) : (
                    <MenuIcon className='block h-6 w-6' aria-hidden='true'/>
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='px-2 pt-2 pb-3 space-y-1'>
              {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
              <Link to='#home' className={'bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium'}>
                Home
              </Link>
              <Link to='#about' className={'bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium'}>
                About Me
              </Link>
              <Link to='#skills' className={'bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium'}>
                Skills
              </Link>
              <Link to='#contact' className={'bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium'}>
                Contact Me
              </Link>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default NavBar;
